import Image from "next/image";
import styles from '../style/project/OurExecutedProjects.module.css';
import projects from '@/data/360-project.json';
import { CountryCode } from '@/lib/constants';
import { NavLink } from '../ui';
import imageLoader, { staticLoader } from "@/lib/helpers/imageLoader";

export function OurExecutedProjects(props:{countryCode:CountryCode}){
    const data=projects && projects.filter(f=>f.countryCode==props.countryCode) || [];
    if(!data || data.length==0)return null;

    const siteURL=process.env.SITE_URL+"/"+props.countryCode;
    return (
        <section className="section Walkthrough">
            <div className="containerFull">
                <h2 className="heading textCenter font26 text222 f500 mb12 fontSM20">A 360-Degree Tour Of Our Executed Projects</h2>
                <p className="subHeading textCenter mb46 font16 fontSM14">Like our designs? Explore them from a different perspective!</p>
                <div className={styles.walkthroughBox}>
                    <div className={styles.vrBigBox}>
                        { data.slice(0, 2).map((d,idx)=> 
                            <div className={styles.vrBigTiles} key={idx}>
                                <NavLink href={siteURL+'/360-degree-view-delivered-projects/'+d.location.toLowerCase()}>
                                    <figure>
                                        <Image width={648} height={400}  loader={imageLoader} src={d.relativePath} alt={d.thumbnailTitle}/>
                                    </figure>
                                    <div className={styles.vrBoxuperimg}>
                                        <Image width={55} height={36} src="/assets/images/VRlogo.svg" alt="VRlogo" />
                                    </div>
                                    <div className={styles.vrbigLtextbox}>
                                        <span className="font16 textfff f500 fontSM14">{d.location}</span>
                                    </div>
                                </NavLink>
                            </div>
                        )}
                    </div>
                    
                        <div className={styles.vrBigBox}>
                            { data.slice(2, 5).map((d,idx)=> 
                                <div className={styles.vrBigTiles} key={idx}>
                                    <NavLink href={siteURL+'/360-degree-view-delivered-projects/'+d.location.toLowerCase()}>
                                        <figure>
                                            <Image width={424} height={280}  loader={imageLoader} src={d.relativePath} alt={d.thumbnailTitle}/>
                                        </figure>
                                        <div className={styles.vrBoxuperimg}>
                                            <Image width={55} height={36} src="/assets/images/VRlogo.svg" alt="VRlogo" />
                                        </div>
                                        <div className={styles.vrbigLtextbox}>
                                            <span className="font16 textfff f500 fontSM14">{d.location}</span>
                                        </div>
                                    </NavLink>
                                </div>
                            )}
                        </div>
                        <NavLink href={siteURL+"/360-degree-view-delivered-projects"} className="btn btnPrimery btnCenter">Experience It Now</NavLink>
                    {/* <div className={`${styles.walkthroughCity} displayGrid grid3 gridSM2`}>
                        {
                            data.map((d,idx)=>
                                <NavLink href={siteURL+'/360-degree-view-delivered-projects/'+d.location.toLowerCase()} key={idx} >
                                    <div className={`${styles.walkthroughItem} ${styles.walkthroughItemSlice}`}>
                                    <p className={styles.view360}><Image
                                        width={17}
                                        height={10}
                                        loader={staticLoader}
                                        alt={`View Delivered Projects in ${d.location} with VR Headset`}
                                        src="/assets/images/vr-eye-logo.png"
                                         /></p>
                                        <p className={`${styles.cityName} textfff`}>{d.location}</p>
                                        <div className={styles.imgBx}>
                                            <Image
                                                width={231}
                                                height={173}
                                                loader={imageLoader}
                                                src={d.relativePath}
                                                alt={d.thumbnailTitle}
                                                className="imgFluid"
                                                />
                                        </div>
                                </div>
                                </NavLink>
                            )
                        }
                    </div>
                    <div className={`${styles.ViewExperince360} bgPink`}>
                        <div className="font30 textfff lineHeight40 fbold textCenter mb30 fontSM20 lineheightSM3 mbSM15">A Virtual Tour of Projects Done in The Past </div>
                        <NavLink href={siteURL+"/360-degree-view-delivered-projects"} className="btn btnSecondery btnBlock">Experience It Now</NavLink>
                    </div> */}
                </div>
            </div>
        </section>
    );
}